@include keyframes(hvr-bob) {
  0% {
  	@include translate(0,-8px);
  }
  50% {
    @include translate(0,-4px);
  }
  100% {
    @include translate(0,-8px);
  }
}

@include keyframes(hvr-bob-float) {
  100% {
    @include translate(0,-8px);
  }
}

@include keyframes(line-scroll-down) {
    0% {
        // @include translate(0,150%);
        bottom: -50px;
    }

    100% {
        // @include translate(0,-250%);
        bottom: 150px;
    }
}
.bg-gradient-cyan {
	position: relative;
	z-index: 1;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		opacity: .8;
		background: rgb(0,160,160);
		background: -moz-linear-gradient(315deg, rgba(0,160,160,1) 0%, rgba(0,54,130,1) 100%);
		background: -webkit-linear-gradient(315deg, rgba(0,160,160,1) 0%, rgba(0,54,130,1) 100%);
		background: linear-gradient(315deg, rgba(0,160,160,1) 0%, rgba(0,54,130,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00a0a0",endColorstr="#003682",GradientType=1);
	}
}
.bg-gradirent-yellow {
	position: relative;
	z-index: 1;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background: rgb(255,165,21);
		background: -moz-linear-gradient(270deg, rgba(255,165,21,1) 0%, rgba(255,84,21,1) 100%);
		background: -webkit-linear-gradient(270deg, rgba(255,165,21,1) 0%, rgba(255,84,21,1) 100%);
		background: linear-gradient(270deg, rgba(255,165,21,1) 0%, rgba(255,84,21,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa515",endColorstr="#ff5415",GradientType=1);
	}
}
.arr-right {
	position: relative;
	padding: 0 14px 0 0;
	&:after {
		content: '';
		display: inline-block;
		width: 9px;
		height: 9px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		@include rotate(45);
		position: absolute;
		top: 50%;
		margin-top: -4px;
		right: 2px;
	}
}
.arr-left {
	position: relative;
	padding: 0 0 0 14px;
	&:after {
		content: '';
		display: inline-block;
		width: 9px;
		height: 9px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		@include rotate(-135);
		position: absolute;
		top: 50%;
		margin-top: -4px;
		left: 2px;
	}
}
.cmn-title {
	text-align: center;
	@include font-line-spacing(45,55,20);
	color: #003682;
	font-weight: 500;
	text-transform: uppercase;
	@include min-screen(768px) {
		@include font-line-spacing(38,38,20);
	}
	@include min-screen(992px) {
		@include font-line-spacing(45,55,20);
	}
	@include SP {
		@include font-line-spacing(40,48,20);
	}
	@include max-screen(360px) {
		@include font-line-spacing(32,40,20);
	}
}
.cmn-sub-tit {
	@include font-line-spacing(25,40,20);
	font-weight: 500;
	@include min-screen(768px) {
		@include font-line-spacing(20,35,20);
	}
	@include min-screen(992px) {
		@include font-line-spacing(25,40,20);
	}
	@include SP {
		@include font-line-spacing(25,33,20);
	}
	@include max-screen(360px) {
		@include font-line-spacing(20,28,20);
	}
}
.cmn-txt {
	p {
		@include font-line-spacing(15,23,20);
		&:not(:last-child) {
			margin: 0 0 23px;
		}
		@include max-screen(360px) {
			@include font-line-spacing(14,22,20);
		}
	}
}
.cmn-btn {
	background: #003682;
	max-width: 250px;
	width: 100%;
	text-align: center;
	height: 65px;
	display: block;
	line-height: 61px;
	@include font-size(15);
	letter-spacing: 0.1em;
	font-weight: 600;
	color: #fff;
	border: 2px solid #003682;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #1D1D1D;
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
		-webkit-transition-property: transform;
		transition-property: transform;
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
	}
	@include PC {
		&:hover {
			border: 2px solid #1D1D1D;
			color: #fff;
			opacity: 1;
			&:before {
				-webkit-transform: scaleX(1);
				transform: scaleX(1);
			}
		}
	}
	&--white {
		background: #fff;
		color: #003682;
		&:before {
			background: #003682;
		}
		@include PC {
			&:hover {
				border: 2px solid #003682;
			}
		}
	}
	&--default {
		background: #fff;
		border: 2px solid #343434;
		color: #343434;
		&:before {
			background: #343434;
		}
		@include PC {
			&:hover {
				color: #fff;
				border: 2px solid #343434;
			}
		}
	}
}

.cmn-sub-visual {
	background: url('../img/common/other/main_visual.jpg') 0 0/cover no-repeat;
	height: 350px;
	@include flexbox();
	@include justify-content(center);
	@include align-items(center);
	padding: 15px;
	@include SP {
		height: 200px;
		padding: 45px 15px 15px;
	}
}
.cmn-breadcrum {
	border-bottom: 1px solid rgba(0,0,0,0.15);
	padding: 16px 0;
	@include SP {
		padding: 8px 0;
	}
	ul {
		li {
			@include font-line-spacing(13,16,20);
			color: $color_blue;
			@include clamp(1);
			a {
				color: $colortext;
			}
		}
	}
}
.cmn-title-subpage {
	color: #fff;
	text-align: center;
	@include font-line-spacing(50,58,20);
	font-weight: 500;
	text-transform: uppercase;
	@include SP {
		@include font-line-spacing(35,53,20);
	}
}
.cmn-pagenavi {
	text-align: center;
	font-size: 0;
	line-height: 0;
	margin: 32px 0 0;
	@include SP {
		margin: 40px 0 0;
	}
	.pages {
		display: none;
	}
	a,span {
		width: 50px;
		height: 50px;
		display: inline-block;
		line-height: 50px;
		margin: 0 4px;
		@include font-size(13);
		color: $colortext;
		@include SP {
			width: 45px;
			height: 45px;
			line-height: 45px;
			margin: 0 2px;
		}
		@include max-screen(360px) {
			width: 35px;
			height: 35px;
			line-height: 35px;
		}
	}
	span.current {
		background: #59616C;
		color: #fff;
	}
	.page {
		@include PC {
			&:hover {
				background: #59616C;
				color: #fff;
				opacity: 1;
			}
		}
	}
	.previouspostslink,
	.nextpostslink {
		background: rgba(89,97,108,0.1);
		position: relative;
		text-indent: -99999px;
		&:before {
			content: '';
			display: inline-block;
			width: 14px;
			height: 14px;
			border-top: 1px solid #59616C;
			border-right: 1px solid #59616C;
			position: absolute;
			top: 50%;
			margin-top: -7px;
			@include SP {
				width: 11px;
				height: 11px;
				margin-top: -6px;
			}
			@include max-screen(360px) {
				width: 10px;
				height: 10px;
				margin-top: -5px;
			}
		}
		@include PC {
			&:hover {
				background: #59616C;
				opacity: 1;
				&:before {
					border-top: 1px solid #fff;
					border-right: 1px solid #fff;
				}
			}
		}
	}
	.nextpostslink {
		&:before {
			@include rotate(45);
			left: 16px;
			@include max-screen(360px) {
				left: 10px;
			}
		}
	}
	.previouspostslink {
		&:before {
			@include rotate(-135);
			right: 16px;
			@include max-screen(360px) {
				right: 10px;
			}
		}
	}
}
.cmn-singe-pagination {
	position: relative;
	text-align: center;
	margin: 40px 0 0;
	height: 50px;
	@include clearfix();
	a {
		color: $colortext;
		height: 100%;
	}
	.btn {
		position: absolute;
		top: 0;
		width: 170px;
		@include flexbox();
		@include align-items(center);
		span {
			padding: 0 18px;
			@include SP {
				display: none;
			}
		}
		&:before {
			content: '';
			width: 50px;
			height: 50px;
			background: #000;
			display: inline-block;
			position: absolute;
			top: 0;
		}
		&:after {
			content: '';
			display: inline-block;
			width: 14px;
			height: 14px;
			border-top: 1px solid #fff;
			border-right: 1px solid #fff;
			position: absolute;
			top: 50%;
			margin-top: -7px;
		}
		&-prev {
			left: 0;
			@include justify-content(flex-start);
			padding: 0 0 0 50px;
			&:before {
				left: 0;
			}
			&:after {
				@include rotate(-135);
				left: 22px;
			}
		}
		&-next {
			right: 0;
			@include justify-content(flex-end);
			padding: 0 50px 0 0;
			&:before {
				right: 0;
			}
			&:after {
				@include rotate(45);
				right: 22px;
			}
		}
	}
	.btn-backlist {
		display: inline-block;
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		width: 200px;
		margin: 0 auto;
		span {
			position: relative;
			display: inline-block;
			&:before {
				content: '';
				display: inline-block;
				width: 14px;
				height: 14px;
				border-top: 1px solid #59616C;
				border-right: 1px solid #59616C;
				position: absolute;
				top: 50%;
				margin-top: -7px;
				@include rotate(-135);
				left: -20px;
				@include SP {
					display: none;
				}
			}
		}
	}
}
.cmn-box-navi {
	background: #fff;
	padding: 32px 0;
	@include SP {
		padding: 36px 0;
	}
}
.cmn-list-navi {
	@include flexbox();
	@include flex-wrap(wrap);
	@include justify-content(center);
	li {
		max-width: 378px;
		width: 100%;
		padding: 8px;
		overflow: hidden;
		@include min-screen(768px) {
			max-width: 50%;
		}
		@include min-screen(992px) {
			max-width: 378px;
		}
		@include SP {
			max-width: 100%;
			padding: 4px 0;
		}
		a,span {
			@include font-line-spacing(15,23,50);
			font-weight: 600;
			color: $color_blue;
			display: block;
			text-align: center;
			padding: 10px 20px 9px;
			border: 2px solid $color_blue;
			position: relative;
			@include flexbox();
			@include align-items(center);
			text-transform: capitalize;
			height: 100%;
			@include justify-content(center);
			&:before,
			&:after {
				content: '';
				width: 40px;
				height: 40px;
				background: #fff;
				position: absolute;
				z-index: 1;
				pointer-events: none;
			}
			&:before {
				top: -20px;
				left: -20px;
				border-right: 2px solid $color_blue;
				@include rotate(45);
			}
			&:after {
				bottom: -20px;
				right: -20px;
				border-left: 2px solid $color_blue;
				@include rotate(45);
			}
			&:hover {
				color: #fff;
				opacity: 1;
				background: $color_blue;
			}
		}
	}
}
.cmn-product {
	padding: 120px 0;
	position: relative;
	&__blocks {
		padding: 80px 0 0;
		&--item {
			@include flexbox();
			@include flex-wrap(wrap);
			@include justify-content(space-between);
			&:not(:last-child) {
				margin: 0 0 80px;
				@include SP {
					margin: 0 0 120px;
				}
			}
			.box-img {
				width: 48.55%;
				@include SP {
					width: 100%;
				}
				img {
					width: 100%;
					display: block;
				}
			}
			.box-content {
				width: 51.45%;
				padding: 0 0 0 30px;
				margin: 12px 0 0;
				@include SP {
					width: 100%;
					padding: 0;
				}
				&-wrap {
					max-width: 500px;
					float: right;
					@include SP {
						max-width: 100%;
						float: none;
					}
				}
				&--title {
					margin: 0 0 18px;
					.logo-product {
						text-align: center;
						height: 71px;
						max-width: 210px;
						width: 100%;
						margin: 0 0 12px;
						@include SP {
							margin: 0 auto 12px;
						}
						span {
							background: #EAEAEA;
							@include border-radius(999px);
							display: inline-block;
							@include flexbox();
							min-width: 148px;
							height: 100%;
							@include align-items(center);
							@include justify-content(center);
							img {
								@include add_prefix(mix-blend-mode,darken);
							}
						}
						&.vbeenet {
							max-width: 150px;
						}
					}
					.cmn-sub-tit {
						text-transform: capitalize;
						@include SP {
							text-align: center;
						}
					}
				}
				&--img {
					margin: 0 0 40px;
					img {
						width: 100%;
						display: block;
					}
				}
				&--ctn {
					margin: 0 0 40px;
				}
				&--btn {
					.cmn-btn {
						@include SP {
							margin: 0 auto;
						}
					}
				}
			}
			&:nth-child(2n) {
				.box-img {
					@include order(2);
				}
				.box-content {
					padding: 0 30px 0 0;
					@include SP {
						padding: 0;
					}
					&-wrap {
						float: left;
						@include SP {
							float: none;
						}
					}
				}
			}
		}
	}
}