@charset 'UTF-8';
.footer {
	&__info {
		background: url('../img/common/other/footer_bg.jpg') 0 0/cover no-repeat;
		padding: 70px 0;
		overflow: hidden;
		@include SP {
			background-image: url('../img/common/other/footer_bg_sp.jpg');
		}
		.cmn-title {
			color: #fff;
		}
		.cmn-txt {
			color: #fff;
			margin: 40px 0 0;
			text-align: center;
		}
		.box-tel {
			margin: 40px 0 0;
			text-align: center;
			p {
				color: #fff;
				@include SP {
					border: 2px solid #fff;
					height: 75px;
				}
				&:not(:last-child) {
					margin: 0 0 17px;
					@include SP {
						margin: 0 0 15px;
					}
				}
				span {
					background: url('../img/common/icon/ico_tel.svg') 0 0/35px 35px no-repeat;
					display: inline-block;
					padding: 0 0 0 43px;
					@include SP {
						background: url('../img/common/icon/ico_tel.svg') center left/25px 25px no-repeat;
					}
				}
				em {
					color: #fff;
					display: inline-block;
					font-style: normal;
					font-weight: 500;
					padding: 0 8px 0 0;
					@include font-line-spacing(30,37);
					@include SP {
						background: none;
						display: block;
						font-weight: 400;
						padding: 0;
						text-align: left;
						@include font-line-spacing(12,23);
					}
				}
				a {
					color: #fff;
					display: inline-block;
					font-weight: 600;
					@include font-line-spacing(30,37,20);
					@include SP {
						@include font-line-spacing(20,25,100);
						color: #fff;
						height: 100%;
						padding: 15px 10px;
						@include flexbox();
						@include justify-content(center);
						@include align-items(center);
						@include flex-direction(column);
					}
					@include max-screen(360px) {
						@include font-line-spacing(17,25,100);
					}
					small {
						@include SP {								
							display: block;
						}
					}
				}
				&.box-tel--mobile {
					em {
						@include SP {
							display: none;
						}
					}
				}
			}
		}
		.btn-contact {
			a {
				background: rgb(255,165,21);
				background: -moz-linear-gradient(270deg, rgba(255,165,21,1) 0%, rgba(255,84,21,1) 100%);
				background: -webkit-linear-gradient(270deg, rgba(255,165,21,1) 0%, rgba(255,84,21,1) 100%);
				background: linear-gradient(270deg, rgba(255,165,21,1) 0%, rgba(255,84,21,1) 100%);
				color: #fff;
				display: block;
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa515",endColorstr="#ff5415",GradientType=1);
				font-weight: 600;
				height: 75px;
				letter-spacing: 0.1em;
				line-height: 75px;
				margin: 40px auto 0;
				max-width: 300px;
				position: relative;
				text-align: center;
				text-transform: uppercase;
				width: 100%;
				z-index: 1;
				@include font-size(15);
				@include SP {
					margin: 18px auto 0;
					max-width: 100%;
				}
				&:before {
					background: #1D1D1D;
					bottom: 0;
					content: "";
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					-webkit-transform: scaleX(0);
					transform: scaleX(0);
					-webkit-transform-origin: 0 50%;
					transform-origin: 0 50%;
					-webkit-transition-duration: 0.3s;
					transition-duration: 0.3s;
					-webkit-transition-property: transform;
					transition-property: transform;
					-webkit-transition-timing-function: ease-out;
					transition-timing-function: ease-out;
					z-index: -1;
				}
				span {
					background: url('../img/common/icon/ico_mail.svg') left center/30px 24px no-repeat;
					display: inline-block;
					padding: 0 0 0 43px;
				}
				&:hover {
					color: #fff;
					opacity: 1;
					&:before {
						-webkit-transform: scaleX(1);
						transform: scaleX(1);
					}
				}
			}
		}
	}
	&__sitemap {
		background: #1D1D1D;
		padding: 30px 0;
		position: relative;
		overflow: hidden;
		@include SP {
			padding: 70px 0 30px;
			// padding: 70px 0 50px;
		}
		&__blocks {
			@include flexbox();
			@include flex-wrap(wrap);
			@include align-items(center);
			position: relative;
			&--info {
				padding: 0 20px 0 0;
				width: 57%;
				@include SP {
					padding: 0;
					text-align: center;
					width: 100%;
				}
				.f-logo {
					a {
						display: block;
					}
				}
				.address {
					color: #fff;
					margin: 16px 0 0;
					@include font-line-spacing(13,21,20);
					@include max-screen(360px) {
						@include font-line-spacing(12,21,20);
					}
				}
				.list-tel {
					font-size: 0;
					line-height: 0;
					margin: 16px 0 0;
					@include SP {
						@include flexbox();
						@include flex-wrap(wrap);
						margin: 12px -5px 0;
						overflow: hidden;
					}
					li {
						color: #fff;
						display: inline-block;
						@include font-line-spacing(13,21,20);
						@include SP {
							padding: 2px 5px;
							width: 100%;
						}
						@include max-screen(360px) {
							@include font-line-spacing(11,21,20);
						}
						&:not(:last-child) {
							padding: 0 24px 0 0;
							@include SP {
								padding: 0 5px;
							}
						}
						span {
							font-weight: 600;
						}
						a {
							color: #fff;
							@include SP {
								text-decoration: underline;
							}

						}
						// &.l-sale {
						// 	@include SP {
						// 		display: none;
						// 	}
						// }
					}
				}
			}
			&--links {
				width: 43%;
				@include SP {
					margin: 43px 0 0;
					padding: 0;
					width: 100%;
				}
				.link {
					float: right;
					font-size: 0;
					line-height: 0;
					@include SP {
						@include flexbox();
						@include justify-content(space-between);
						float: none;
						overflow: hidden;
					}
					li {
						display: inline-block;
						&:not(:last-child) {
							padding: 0 30px 0 0;
							@include SP {
								padding: 0;
							}
						}
						@include SP {
							margin: 5px 0;
							width: 100%;
						}
						a {
							color: #fff;
							font-weight: 500;
							@include font-line-spacing(15,23,20);
							@include SP {
								@include font-line-spacing(13,12,20);
								border-left: 1px solid #525252;
								display: block;
								padding: 0 5px;
								text-align: center;
							}
							@include max-screen(360px) {
								@include font-line-spacing(11,12,20);
								padding: 0 5px;
							}
							&:hover {
								color: #FFA515;
								opacity: 1;
							}
						}
						&:first-child {
							a {
								@include SP {
									border-left: none;
								}
							}
						}
					}
				}
				html:lang(vi) & {
					.link {
						@include SP {
							@include flex-wrap(wrap);
							@include justify-content(center);
						}
						li {
							@include SP {
								width: 33.33%;
							}
							a {
								@include SP {
									display: block;
									text-align: center;
								}
							}
							&:first-child {
								a {
									@include SP {
										border-left: 1px solid #525252;
									}
								}
							}
							&:nth-child(3),
							&:last-child {
								a {
									@include SP {
										border-right: 1px solid #525252;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
#page-top {
	bottom: 20px;
	display: none;
	height: 50px;
	position: fixed;
	right: 30px;
	width: 50px;
	z-index: 1000;
	@include transition_c(0.3s);
	@include SP {
		bottom: 10px;
		right: 10px;
	}
	&.active {
		// position: absolute;
		// right: 30px;
		// @include SP {
		// 	position: absolute;
		// 	margin-right: -30px;
		// 	margin-top: -95px;
		// 	right: 50%;
		// 	bottom: inherit;
		// 	top: 0;
		// }
	}
	a {
		@include flexbox();
		height: 100%;
		@include align-items(center);
		@include justify-content(center);
		@include SP {
			background: url('../img/common/other/page_top.svg') center center no-repeat;
		}
		img {
		    @include SP {
		    	width: 60px;
		    }
		}
	}
}
.copyright {
	background: #1D1D1D;
	height: 100%;
	left: 0;
	padding: 30px 0;
	position: fixed;
	text-align: center;
	top: 0;
	width: 78px;
	z-index: 1001;
	@include flexbox();
	@include flex-direction(column);
	@include align-items(center);
	@include justify-content(space-between);
	@include min-screen(768px) {
		width: 50px;
	}
	@include min-screen(992px) {
		width: 78px;
	}
	@include SP {
		height: auto;
		margin: 43px 0 0;
		padding: 0;
		position: static;
		width: 100%;
	}
	p {
		color: #fff;
		@include writing-mode(v, rl);
		@include rotate(180);
		@include SP {
			@include rotate(0);
			@include add_prefix(writing-mode,horizontal-tb);
		}

	}
	.copy {
		@include font-line-spacing(13,21);
		@media (min-height: 320px) {
			@include font-size(9);
		}
		@media (min-height: 700px) {
			@include font-size(13);
		}
		@include max-screen(360px) {
			@include font-line-spacing(11,21);
		}
	}
	.scroll-down {
		@include font-line-spacing(12,20);
		font-weight: 500;
		height: 150px;
		overflow: hidden;
		position: relative;
		z-index: 1;
		@media (min-height: 320px) {
			@include font-size(9);
		}
		@media (min-height: 700px) {
			@include font-size(13);
		}
		&:before {
			background: #fff;
			bottom: 0;
			content: '';
			display: inline-block;
			height: 50px;
			position: absolute;
			right: 50%;
			width: 1px;
			z-index: 0;
			@include add_prefix(animation-name, line-scroll-down);
			@include add_prefix(animation-duration, 3s);
			@include add_prefix(animation-delay, 0s);
			@include add_prefix(animation-timing-function, linear);
			@include add_prefix(animation-iteration-count, infinite);
			@include add_prefix(animation-fill-mode, forwards);
			@include add_prefix(animation-direction, normal);
		}
		@include SP {
			display: none;
		}
		span {
			background: #1d1d1d;
			display: inline-block;
			padding: 8px 0;
			position: relative;
			z-index: 1;
		}
	}
}