@charset 'UTF-8';
.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 0 0 0 78px;
	background: transparent;
	z-index: 1000;
	height: 92px;
	@include min-screen(768px) {
		padding: 0 0 0 50px;
		height: 67px;
	}
	@include min-screen(992px) {
		padding: 0 0 0 78px;
		height: 92px;
	}
	@include SP {
		padding: 0 15px;
		height: 64px;
	}
	&.fixHeader {
		background: #fff;
		@include box-shadow(0, 3px, 15px, rgba(0,0,0,0.1));
		.header__info--logo .logo a img {
			&.logo-black {
				display: block;
			}
			&.logo-white {
				display: none;
			}
		}
		.header__info--gnavi nav .menu li {
			a {
				color: $colortext;
				@include PC {
					&:hover {
						color: #003682;
					}
				}
			}
			>a {
				&.is-current {
					color: #003682;
				}
			}
			&.has-sub-menu {
				>a {
					.caret {
						border-top-color: #343434;
					}
				}
				&.is-active {
					>a {
						color: #003682;
						>.caret {
							border-top-color: #003682;
						}
					}
				}
			}
		}
		.menu-btn {
			background: #003682;
			.burger-icon {
				background-color: #fff;
				&:before,
				&:after {
					background-color: #fff;
				}
			}
		}
	}
	&__info {
		@include flexbox();
		@include justify-content(space-between);
		@include align-items(center);
		height: 100%;
		padding: 21px 0;
		@include min-screen(768px) {
			padding: 18px 0;
		}
		@include min-screen(992px) {
			padding: 21px 0;
		}
		&--logo {
			padding: 0 0 0 97px;
			width: 27%;
			@include min-screen(768px) {
				padding: 0 0 0 20px;
			}
			@include min-screen(992px) {
				padding: 0 0 0 20px;
			}
			@include min-screen(1300px) {
				padding: 0 0 0 97px;
			}
			@include SP {
				padding: 0;
				width: 60%;
			}
			.logo {
				a {
					display: block;
					img {
						&.logo-black {
							display: none;
						}
						width: 100%;
						@include min-screen(768px) {
							max-width: 150px;
						}
						@include min-screen(992px) {
							max-width: 200px;
						}
						@include min-screen(1200px) {
							max-width: 317px;
						}
						@include SP {
							max-width: 190px;
						}
						@include max-screen(360px) {
							max-width: 160px;
						}
					}
				}
			}
		}
		&--gnavi {
			padding: 0 136px 0 0;
			@include min-screen(768px) {
				padding: 0 100px 0 0;
				display: block !important;
			}
			@include min-screen(992px) {
				padding: 0 136px 0 0;
			}
			@include SP {
				display: none;
				padding: 54px 0;
				position: fixed;
				top: 0;
				left: 0;
				background: #fff;
				z-index: 9998;
				width: 100%;
				height: 100%;
				overflow-y: auto;
			}
			@include max-screen(360px) {
				padding: 70px 0;
			}
			nav {
				@include flexbox();
				@include align-items(center);
				@include SP {
					@include flex-direction(column);
				}
				.caret {
				    display: inline-block;
					width: 0;
					height: 0;
					margin-bottom: 2px;
					margin-left: 2px;
					vertical-align: middle;
					content: "";
					border-color: #333 transparent transparent;
					border-style: solid solid none;
					border-width: 4px 4px 0;
				    border-top-color: #fff;
				    @include transition_c(0.3s);
				    @include SP {
				    	border-top-color: #343434;
				    	position: absolute;
				    	right: 25px;
				    	top: 50%;
				    	@include translate(0,-50%);
				    }
				}
				.menu {
					font-size: 0;
					line-height: 0;
					@include SP {
						width: 100%;
						padding: 0 15px;
					}
					>li {
						display: inline-block;
						padding: 0 20px;
						position: relative;
						@include min-screen(768px) {
							padding: 0 5px;
						}
						@include min-screen(992px) {
							padding: 0 10px;
						}
						@include min-screen(1300px) {
							padding: 0 20px;
						}
						@include SP {
							display: block;
							padding: 0;
							border-bottom: 1px solid rgba(0,54,130,0.2);
						}
						>a {
							display: inline-block;
							@include font-line-spacing(15,23,30);
							color: #fff;
							font-weight: 500;
							@include min-screen(768px) {
								@include font-size(10);
							}
							@include min-screen(992px) {
								@include font-size(11);
							}
							@include min-screen(1200px) {
								@include font-size(15);
							}
							@include SP {
								color: $colortext;
								text-align: center;
								display: block;
								@include font-line-spacing(20,33,20);
								padding: 19px 0;
								position: relative;
							}
							@include PC {
								&:hover {
									color: #FFA515;
									opacity: 1;
								}
							}
							&.is-current {
								color: #FFA515;
							}
						}
						&.has-sub-menu {
							&.is-active {
								>a {
									color: #FFA515;
									@include SP {
										color: $colortext;
									}
									>.caret {
										border-top-color: #FFA515;
										@include rotate(-180);
										@include SP {
											border-top-color: $colortext;
										}
									}
								}
							}
						}
					}
					.sub-menu-wrap {
						position: absolute;
						top: 100%;
						left: 20px;
						width: 280px;
						display: none;
						@include min-screen(768px) {
							width: 220px;
						}
						@include min-screen(992px) {
							width: 280px;
						}
						@include SP {
							position: static;
							width: 100%;
						}
						.sub-menu {
							margin: 16px 0 0;
							position: relative;
							&:before {
								content: '';
								width: 0; 
								height: 0; 
								border-left: 4px solid transparent;
								border-right: 4px solid transparent;
								border-bottom: 8px solid #343434;
								position: absolute;
								left: 20px;
								top: -8px;
								@include SP {
									display: none;
								}
							}
							@include SP {
								margin: 0;
								padding: 0;
							}
							li {
								display: block;
								&:not(:last-child) {
									border-bottom: 1px solid #fff;
									@include SP {
										border-width: 2px;
									}
								}
								a {
									display: block;
									color: #fff;
									background: #343434;
									@include font-line-spacing(12,23,50);
									font-weight: 500;
									text-transform: capitalize;
									position: relative;
									padding: 8px 18px;
									@include min-screen(768px) {
										@include font-size(10);
									}
									@include min-screen(992px) {
										@include font-size(11);
									}
									@include min-screen(1200px) {
										@include font-size(15);
									}
									.caret {
										position: absolute;
										right: 15px;
										top: 50%;
										@include translate(0,-50%);
										display: none;
										@include SP {
											right: 25px;
										}
									}
									@include SP {
										display: block;
										text-align: center;
										color: #fff;
										@include font-line-spacing(16,23,50);
										padding: 13px 20px;
									}
									@include PC {
										&:hover {
											color: #FFA515;
											opacity: 1;
										}
									}
								}
								&.has-child-menu {
									a {
										.caret {
											display: block;
											@include SP {
												border-top-color: #fff;
											}
										}
										&.is-active {
											@include SP {
												color: #FFA515;
											}
											.caret {
												@include rotate(-180);
											}
										}
									}
								}
							}
						}
					}
					.last-menu-wrap {
						background: #fff;
						display: none;
						.last-menu {
							>li {
								&:not(:last-child) {
									border-bottom: 1px solid rgba(0,54,130,0.2);
									@include SP {
										border-bottom: 2px solid #fff;
									}
								}
								>a {
									color: $colortext;
									@include transition_c(0.3s);
									background: #fff;
									@include SP {
										font-weight: 400;
										background: rgba(82,82,82,0.15);
										border-bottom: none;
									}
									@include PC {
										&:hover {
											background: rgba(82,82,82,0.15);
											color: $colortext;
											font-weight: 600;
										}
									}
								}
							}
						}
					}
				}
				.language {
					width: 60px;
					height: 24px;
					background: #003682;
					@include border-radius(999px);
					font-size: 0;
					line-height: 0;
					text-align: center;
					padding: 3px;
					@include flexbox();
					@include flex-wrap(wrap);
					@include flex(0 0 auto);
					margin: 0 40px 0 20px;
					@include min-screen(768px) {
						margin: 0 10px 0 5px;
					}
					@include min-screen(992px) {
						margin: 0 40px 0 20px;
					}
					@include SP {
						width: 112px;
						height: 45px;
						margin: 115px 0 0;
					}
					li {
						width: 50%;
						a {
							color: #fff;
							@include font-size(11);
							line-height: 18px;
							font-weight: 500;
							display: block;
							@include SP {
								@include font-size(20);
								line-height: 39px;
							}
							&.is-active {
								background: #fff;
								@include border-radius(999px);
								color: #003682;
							}
						}
					}
				}
			}
		}
		&--button {
			position: absolute;
			right: 0;
			top: 0;
			width: 136px;
			height: 92px;
			@include flexbox();
			@include flex-wrap(wrap);
			@include min-screen(768px) {
				width: 100px;
				height: 67px;
			}
			@include min-screen(992px) {
				width: 136px;
				height: 92px;
			}
			@include SP {
				width: 128px;
				height: 64px;
			}
			.btn-contact {
				width: 100%;
				height: 100%;
				@include SP {
					width: 64px;
					height: 64px;
				}
				a {
					display: block;
					background: url('../img/common/other/btn_contact_bg.png') 0 0/cover no-repeat;
					height: 100%;
					text-align: center;
					@include font-line-spacing(15,23,20);
					font-weight: 500;
					color: #fff;
					@include flexbox();
					@include flex-wrap(wrap);
					@include flex-direction(column);
					@include align-items(center);
					@include justify-content(center);
					@include min-screen(768px) {
						@include font-size(12);
					}
					@include min-screen(992px) {
						@include font-size(15);
					}
					span {
						display: block;
						margin: 8px 0 0;
						@include SP {
							display: none;
						}
					}
					img {
						@include min-screen(768px) {
							width: 27px;
						}
						@include min-screen(992px) {
							width: 37px;
						}
						@include SP {
							width: 30px;
							height: 24px;
						}
					}
					&:hover {
						opacity: 1;
						background-image: url('../img/common/other/btn_contact_bg_b.png');
					}
				}
			}
			.menu-btn {
				border: none;
				outline: none;
				cursor: pointer;
				background: #fff;
				z-index: 9999;
				width: 64px;
				height: 64px;
				display: none;
				padding: 20px 0 0 20px;
				@include transition_c(0.3s);
				@include SP {
					display: block;
				}
				.burger-icon {
					@include burger(25px,3px,5px,#343434,999px);
				}
				&.is-active {
					background: #003682;
					.burger-icon {
						@include burger-to-cross;
						&:before,
						&:after {
							background-color: #fff;
						}
					}
				}
			}
		}
	}
}