@charset "UTF-8";
/*-----------------------------------------------------------
clearfix
------------------------------------------------------------*/
.clearfix:after {
	content: "";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
	line-height: 0;
}
.clearfix {display: inline-block;}
/* exlude MacIE5 \*/
* html .clearfix {height: 1%}
.clearfix {display: block;}
/* end MacIE5 */
/*-----------------------------------------------------------
reset
------------------------------------------------------------*/
p {
	margin: 0;
	padding: 0;
}
ol, ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
input, textarea {
	margin: 0;
	font-size: 100%;
	resize: none;
}
table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 100%;
}
dl, dt, dd, th, td {
	margin: 0;
	padding: 0;
}
h1, h2, h3, h4, h5, h6 {
	/*font-size: 100%;*/
	font-weight: normal;
	margin: 0;
	padding: 0;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, dialog {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
img {
	margin: 0;
	padding: 0;
	vertical-align: middle;
	border: 0;
	max-width:100%;
	height:auto;
}
table img {
	margin: 0;
	padding: 0;
	vertical-align: middle;
	border: 0;
}
article, aside, dialog, figure, footer, header, hgroup, nav, section {display: block;}
nav ul {list-style: none;}
*, *:before, *:after {
	@include add_prefix(box-sizing, border-box);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
*:focus {outline: none!important;}
label, select, button {cursor: pointer;}

// Remove select appearance for IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	select::-ms-expand {
		display: none;
	}
}
@media screen\0 {
	select::-ms-expand {
		display: none;
	}
}

/*-----------------------------------------------------------
Link
------------------------------------------------------------*/
a {
	outline: none;
	transition:0.3s;
	color: $colortext;
	@include PC {
		&:hover {
			text-decoration: none;
			color: #003682;
			opacity: 0.8;
		}
	}
}
a:link, a:visited, a:active {
	text-decoration: none;
}
/*-----------------------------------------------------------
FONT family
------------------------------------------------------------*/

/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	white-space: nowrap;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
}
/*-----------------------------------------------------------
Body
-------------------------------------------------------------*/
html {
	font-size: 62.5%;
	@include add_prefix(text-size-adjust, 100%);
	// @include add_prefix(font-feature-settings, "palt");
}
body {
	@include ffMon;
	@include font-line-spacing(15,23);
	width: 100%;
	color: $colortext;
	margin: 0;
	padding: 0;
	letter-spacing: 0;
	height: 100%;
	word-break: break-word;
	padding: 0 0 0 78px;
	@include min-screen(768px) {
		padding: 0 0 0 50px;
	}
	@include min-screen(992px) {
		padding: 0 0 0 78px;
	}
	@include SP {
		padding: 0;
	}
}

/*-----------------------------------------------------------
Container
------------------------------------------------------------*/
.container-fluid {
	padding: 0 15px;
}
.container {
	padding: 0 15px;
	margin: 0 auto;
	@include clearfix();
	@include min-screen(768px) {
		width: 718px;
	}
	@include min-screen(992px) {
		width: 914px;
	}
	@include min-screen(1200px) {
		max-width: 1200px;
		width: 100%;
	}
}
.w-inner {
	max-width: 900px;
	margin: 0 auto;
}
#wrap {
	overflow: hidden;
	main {
		overflow: hidden;
	}
}
/*-----------------------------------------------------------
common
------------------------------------------------------------*/
.sp, .SP {
	@include min-screen(768px) {display: none!important;}
}

.pc, .PC {
	@include max-screen(767px) {display: none!important;}
}
